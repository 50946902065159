<template>
  <nav>
    <router-link to="/" @click="closeMobileMenu" class="cilab-logo">
    <Logo main_color="cilab-blue"
        second_color="cilab-grey"
        :width="115"
        :height="45"
        class="cilab-logo">
    </Logo>
    </router-link>
    <div id="menuToggle" class="nav-icon" :class="{ open: isMenuActive}" @click="changeMobileMenuStatus">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <ul id="menu" :class="{ active: isMenuActive }">
        <li class="cilab-main-menu-link"> 
          <label for="cilab-product-menu"><span class="cilab-product-menu">Products</span></label>               
          <input type="checkbox" id="cilab-product-menu"> 
          <ul class="cilab-product-sub-menus">
            <li>
                <label for="cilab-product-sub-menus-testsystems"><span class="cilab-product-sub-menu">Test Systems</span></label>               
                <input type="checkbox" id="cilab-product-sub-menus-testsystems"> 

                <ul class="cilab-product-sub-menu-links">
                  <li><router-link to="/product-ci200" @click="closeMobileMenu" class="products-child-menu-item">ci200 - Multi Interface</router-link></li>
                  <li><router-link to="/product-ci220" @click="closeMobileMenu" class="products-child-menu-item">ci220 - NFC</router-link></li>
                  <li><router-link to="/product-ci230" @click="closeMobileMenu" class="products-child-menu-item">ci230 - NFC</router-link></li> 
                </ul>
            </li>

            <li>
                <label for="cilab-product-sub-menus-accessoires"><span class="cilab-product-sub-menu">Accessories</span></label>               
                <input type="checkbox" id="cilab-product-sub-menus-accessoires"> 

                <ul class="cilab-product-sub-menu-links">
                  <li><router-link to="/product-robots" @click="closeMobileMenu" class="products-child-menu-item">Robots</router-link></li> 
                  <li><router-link to="/product-reference-antennas" @click="closeMobileMenu" class="products-child-menu-item">Reference Antennas</router-link></li>
                </ul>
            </li>

          </ul>
        </li>
        <li class="cilab-main-menu-link"><a href="https://support.cilab.com/login_page.php" target="_blank" class="router-link-hover">Support</a></li>
        <li class="cilab-main-menu-link"><router-link to="/about" @click="closeMobileMenu" class="router-link-hover">About</router-link></li>
        <li class="cilab-main-menu-link"><router-link to="/career" @click="closeMobileMenu" class="router-link-hover">Career</router-link></li>
    </ul>
  </nav>
</template>

<script>
import Logo from "@/components/AppLogo.vue"

export default {
  name: 'MobileNavbar',
  components: {
    Logo
  },
  data() {
    return {
      isMenuActive: false,
      isProductMenuOpen: false
    }
  },
  methods: {
    changeMobileMenuStatus() {
      this.isMenuActive = !this.isMenuActive;
      this.isMenuActive ? this.$emit('changedMobileMenuStateOpen') : this.$emit('changedMobileMenuStateClosed')
    },
    closeMobileMenu() {
      this.isMenuActive = false
      this.$emit('changedMobileMenuStateClosed') 
    },
    changeProductsMenuStatus() {
      this.isProductMenuOpen = !this.isProductMenuOpen
    }
  }
}
</script>

<style scoped>
  ul {
    padding: 0;
    list-style-type: none;
  }

  .cilab-logo {
    z-index: 1;
    background-color: white;
    padding-top: 7px;
    padding-bottom: 6px;
  }

  nav {
    background-color: white;
    border-top: 4px solid var(--cilab-blue);
    height: 69px;
    padding: 5px;
    display: flex;
    align-items: center;
  }

  nav a {
    color: #2c3e50;
    text-decoration: none;
  }

  nav a.router-link-exact-active {
    color: var(--cilab-blue);
  }

  /* product menu */
  .cilab-product-menu { 
    cursor : pointer;
    display: block;
  }

  .cilab-product-menu::after {
    content: "+";
    position: relative;
    left: 5%;
  }

  #cilab-product-menu { position: absolute; opacity: 0; max-height: 0px; }    

  #cilab-product-menu:checked + .cilab-product-sub-menus { max-height: 300px; } 

  /* product sub-menus */
  .cilab-product-sub-menus {
    margin-left: 5%;
    max-height: 0px;
    overflow: hidden;
    transition: max-height .4s;
    /* padding: 10px 0 10px 0; */
  }

  .cilab-product-sub-menu {
    cursor : pointer;
    display: block
  }

  .cilab-product-sub-menu::after {
    float: right;
    right: 10%;
    content: "+";
    position: relative;
  }

  .cilab-product-sub-menu-links {
    margin-left: 5%;
    max-height: 0px;
    overflow: hidden;
    transition: max-height .4s;
  }

  #cilab-product-sub-menus-testsystems { position: absolute; opacity: 0; max-height: 0px; }  

  #cilab-product-sub-menus-testsystems:checked + .cilab-product-sub-menu-links { max-height: 150px; } 

  #cilab-product-sub-menus-accessoires { position: absolute; opacity: 0; max-height: 0px; } 

  #cilab-product-sub-menus-accessoires:checked + .cilab-product-sub-menu-links { max-height: 100px; } 

  .active {
    transform: translate(0%, 0) !important;
  }

  #menuToggle {
    position: relative;
    margin-left: auto;
    right: 25px;
    z-index: 1;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle input
  {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }

  /* hamburger-icon */
  .nav-icon{
    width: 35px;
    height: 23px;
    position: relative;
    cursor: pointer;
    display: inline-block;
  }
  .nav-icon span{
    background-color: var(--cilab-grey);
    position: absolute;
    border-radius: 2px;
    transition: .3s cubic-bezier(.8, .5, .2, 1.4);
    width:100%;
    height: 3px;
    transition-duration: 500ms
  }
  .nav-icon span:nth-child(1){
    top:0px;
    left: 0px;
  }
  .nav-icon span:nth-child(2){
    top:10px;
    left: 0px;
    opacity:1;
  }
  .nav-icon span:nth-child(3){
    bottom:0px;
    left: 0px;
  }

  .nav-icon.open span:nth-child(1){
    transform: rotate(45deg);
    top: 10px;
  }
  .nav-icon.open span:nth-child(2){
    opacity:0;
  }
  .nav-icon.open span:nth-child(3){
    transform: rotate(-45deg);
    top: 10px;
  }

  #menu {
    position: absolute;
    width: 200px;
    height: 400px;
    top: 0;
    right: 0;
    margin-top: 0;
    box-shadow: 0 0 10px #85888C;
    border-top: 4px solid var(--cilab-blue);
    padding: 30px;
    padding-top: 60px;
    background-color: white;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
    overflow-y: auto;
    text-align: left;
  }

  #menu li
  {
    padding: 15px 0;
    margin: 10px 0;
  }

  ul.cilab-product-sub-menu-links li {
    padding: 5px 0 !important;
    margin: 0;
  }

  .cilab-main-menu-link {
    padding: 15px 10px !important;
    border: 2px solid var(--cilab-blue);
    border-radius: 4px;
  }
</style>