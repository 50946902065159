<template>
  <BannerTrustech></BannerTrustech>
  <div class="gridRow col-md-3">
    <div class="gridBox">
      <div class="cilab-product-card">
        <img alt="ci200" src="../assets/cilab-ci200.webp">
        <div class="product-information">
            <p class="cilab-product-headline">ci200 - Production Test Equipment</p>
            <ul>
              <li>Multi-channel parallel testing at highest speed</li>
              <li>Scalable from 4 to 32 test heads</li>
              <li>Highest speed for electrical test &amp; personalization</li>
              <br>
            </ul>
            <button><router-link to="/product-ci200">Learn more ></router-link></button>
        </div>
      </div>
    </div>

    <div class="gridBox">
      <div class="cilab-product-card">
        <img alt="ci220" src="../assets/cilab-ci220.webp">
        <div class="product-information">
            <p class="cilab-product-headline">ci220 -  High Speed Contact Test System</p>
            <p class="product-description">
                The ci220 is a next-generation 
                fully integrated contact test 
                system that combines state-of-the-art FPGA and measurement 
                technologies to enable fast, 
                precise, and reliable contact 
                testing, development and debugging.
            </p>
            <button><router-link to="/product-ci220">Learn more ></router-link></button>
        </div>
      </div>
    </div>

    <div class="gridBox">
      <div class="cilab-product-card">
        <img alt="ci230" src="../assets/cilab-ci230.webp">
        <div class="product-information">
            <p class="cilab-product-headline">ci230 - NFC Development &amp; Certification Tool</p>
            <ul>
              <li>World's fastest NFC test system </li>
              <li>Breakthrough debug &amp; development capabilities</li>
              <li>Flexible remote integration &amp; automation</li>
              <li>Support for multiple robot systems</li>
            </ul>
            <button><router-link to="/product-ci230">Learn more ></router-link></button>
        </div>
      </div>
    </div>
  </div>

  <div class="gridRow col-md-1">
    <div class="gridHeading">
      <h1>What’s new</h1>
    </div>
    <div class="gridBox">
      <div class="cilab-news-layout">
        <div class="cilab-news--info">
          <i class="fa-solid fa-video"></i>
        </div>
        <img class="cilab-news--image" src="../assets/PowerTransferMeasurement_3DPlot.webp" alt="powerTransferMeasurement3DPlot">
        <div class="cilab-news--content">
          <h3>See your RF field</h3>
          <p>Watch how Cilab’s NFC test suite can now show device developers their RF field in high resolution graphs based on more than 25,000 measurements taken in just 10 minutes.</p>
          <p>The three-minute video linked in the NFCW article demonstrates how engineers can use the new shmoo plot, intensity chart and 3D plot functions in Cilab’s ci230 NFC testing suite to quickly visualize the RF fields of devices under test.</p>
          <button class="cilab-news--button">
            <i class="fa-solid fa-eye" style="margin-right: 7px;"></i>
            <a target="_blank" href="https://members.nfcw.com/99058/cilabs-nfc-tester-gets-3d-visualisations/">WATCH & READ NOW</a>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="testimonials">
    <div class="gridRow col-md-0">
      <div class="gridHeading">
        <h1>Testimonials</h1>
      </div>
      <div class="gridBox">
        <ul class="cloud" role="navigation">
            <li>
              <a data-weight="2" target="_blank" href="https://www.nfcw.com/2019/09/27/364559/nxp-picks-cilabs-nfc-tester-to-support-customers-developing-emv-3-0-pos-terminals/">
              “I would give this product a ten out of ten. It is quick, easy to use and gives you all the debug information you need.” <br>
              <span class="citation">~ Renke Bienert, senior principal engineer, NXP (click to read full article)</span>
              </a>
            </li>
            <li>
              <a data-weight="2" target="_blank" href="https://www.nfcw.com/2019/10/21/364750/invenco-cilab-emv-development-testing/">
              “I am absolutely amazed by the speed of this thing. It’s a huge time saver.” <br>
              <span class="citation">~ Chris Henry, chief engineer, Invenco (click to read full article)</span>
              </a>
            </li>
            <li>
              <a data-weight="2" target="_blank" href="https://www.nfcw.com/2020/03/04/365918/hid-global-adopts-cilab-nfc-test-equipment-to-standardise-worldwide-labs/">
              “With Cilab’s ci230, we don’t need to tie up our RF experts to do testing.” <br>
              <span class="citation">~ Hans-Juergen Pirch, engineering director, HID Global (click to read full article)</span>
              </a>
            </li>
            <li>
              <a data-weight="2" target="_blank" href="https://www.nfcw.com/2021/03/11/371153/feig-selects-cilab-for-emvco-3-1a-compliance-testing/">
              “Cilab’s ci230 solution allows us to demonstrate the existing robustness of our products and to support our work with POS manufacturers during the integration 
              phase.” <br>
              <span class="citation">~ Manuel Sahm, technical leader, FEIG (click to read full article)</span>
              </a>
            </li>
            <li>
              <a data-weight="2" target="_blank" href="https://www.nfcw.com/nfc-world/nayax-picks-cilab-ci230-to-accelerate-emv-pos-certification/">
              “I was amazed by the ci230’s capabilities. Cilab’s solution allows us to save time and money, accelerate our development cycle and provide the best possible 
              service to our customers. It’s a real game changer.” <br>
              <span class="citation">~ Ran Granot, lab manager, Nayax (click to read full article)</span>
              </a>
            </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="gridRow col-md-1">
    <div class="gridHeading">
      <h1>About cilab GmbH</h1>
    </div>
    <div class="gridBox">
      <p>cilab GmbH is an Austrian based company. We support our customers with our NFC (Near Field Communication) and RFID (Radio Frequency Identification) test systems and innovative solutions.</p>

      <p>We work with a high quality management system to meet your needs. Our ISO 9001:2015 quality management certification together with the ISO/IEC 17025:2017 confirmation for testing services of electronic devices allows us to work with your company today and provides a strong foundation to continue to work together in the future. We welcome challenges and find that when we work together with our clients, new solutions are found by keeping an open mind. By working this way and being flexible, we are able to maintain a fresh approach to meet our client's needs.</p>

      <p>Feel free to send us a request for a quote with a short note about your requirements in our contact message box. We look forward to helping you shorten time to market with your new products.</p>
    </div>
  </div>
</template>

<script>
import BannerTrustech from "@/components/Banners/BannerTrustech.vue";

export default {
    components: {
        BannerTrustech
    }
}
</script>

<style scoped>
  /* product cards */
  img {
    border-radius: 5px 5px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 5px;
    margin: 0 0 20px 0;
  }

  .citation {
    font-style: italic;
    font-size: small;
  }

  button {
    border: 2px solid var(--cilab-blue);
    border-radius: 4px;
    color: var(--cilab-blue) !important;
    background-color: white;
    color: black;
    padding: 5px 18px;
    font-size: 14px;
    cursor: pointer;
  }

  button > a {
    text-decoration: none;
    color: var(--cilab-blue);
  }

  button:hover > a {
    color: white !important;
    transition: .3s;
  }

  button:hover {
    background: #005ca9;
    color: white !important;
    transition: .3s;
  }

  .cilab-product-card {
    transition: 0.3s;
    border-radius: 5px;
    width: 500px;
    margin: 0 auto 30px;
    max-width: 100%;
    box-shadow: 0 2px 6px 0px var(--cilab-grey); 
    text-align: left;
    height: 95%;
  }

  .cilab-product-card:hover {
    box-shadow: 0 8px 16px 0 var(--cilab-grey);
  }

  .cilab-product-card img {
    max-width: 100%;
    vertical-align: middle;
    border: 0;
  }

  .cilab-product-card .product-information {
    padding: 30px 15px 15px;
  }

  .product-description {
    padding: 5px;
    margin: 0 0 20px 0;
  }

  .cilab-product-headline {
    color: #171c2d;
    height: auto;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    z-index: -1;
  }

  .cilab-product-headline::after {
    content: "";
    display: block;
    width: 50px;
    padding-top: 10px;
    border-bottom: 3px solid var(--cilab-blue);
  }

  @media screen and (max-width: 700px) {
    .cilab-product-headline::after {
      top: 45px
    }
  }

  /* testimonials */

  .testimonials {
    width: 100%;
    /* background-color: rgba(var(--cilab-blue-rgb), 0.5); */
  }

  ul.cloud {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }  

  ul.cloud a[data-weight="2"] { --size: 2; }

  ul.cloud a {
    color: #2c3e50;
    display: block;
    background-color: white;
    font-size: 1.5rem;
    padding: 8px;
    margin: 15px;
    text-decoration: none;
    position: relative;
    border-radius: 5px;
    --size: attr(data-weight number, 2); 
    font-size: calc(var(--size) * 0.65rem);
    box-shadow: 0 2px 6px 0px var(--cilab-grey);
    transition: 0.3s;
  } 

  ul.cloud a:hover {
    box-shadow: 0 8px 16px 0 var(--cilab-grey);
  }

  .cilab-news-layout {
    display: flex;
    flex-direction: row; 
    background-color: #f7f7f7;
    border-radius: 10px;
    position: relative;
    transition: .2s;
  }

  .cilab-news-layout:hover {
    box-shadow: 0 2px 6px 0px var(--cilab-grey);
    transition: .3s;
  }

  @media screen and (max-width: 900px) {
    .cilab-news-layout {
      flex-direction: column;
      align-items: center;
    }
  }

  .cilab-news--image {
    width: 350px;
    max-width: calc(100% - 30px);
    max-height: 100%;
    margin: 15px;
    border-radius: 10px;
  }

  .cilab-news--content {
    flex-grow: 1;
    padding: 20px 30px;
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  .cilab-news--content > p {
    margin-top: 0px;
  }

  .cilab-news--button {
    align-self: flex-end;
    margin-top: auto;
    font-size: 16px;
  }

  .cilab-news--info {
    position: absolute;
    right: calc(-25px / 2);
    top: calc(-25px / 2);
    background-image: linear-gradient(to right top, #005ca9, #0073ba, #0089c9, #009fd5, #1cb5e0);
    color: white;
    padding: 10px;
    font-size: 25px;
    border-radius: 25px;
  }
</style>