<template>
    <div class="banner-background gridRow">
        <div class="gridRow col-md-1">
            <div class="trustech-banner-container">
                <div class="gridBox bannerBox">
                    <img class="trustech-banner" src="../../assets/banners/trustech-2024.webp" alt="">
                </div>
                <div class="gridBox trustech-location-text">
                    <p class="trustech-heading">We are on Trustech 2024</p>
                    <p><i class="fa fa-map-marker-alt trustech-location"></i> Find us at <span class="bold">Pavilion 5.2 D 036</span></p>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.banner-background {
    width: 100%;
    background-color: rgb(27, 45, 77) !important;
    color: white;
    margin: 0;
}

.gridRow {
    margin-top: 0;
    margin-bottom: 0;
}

.bannerBox {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trustech-heading {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.375rem;
}

.trustech-location {
    padding: 5px;
}

.trustech-banner-container {
    display: flex;
    justify-content: center;
}

.trustech-banner {
    width: 100%;
    max-width: 600px;
    height: auto;
    padding: 10px;
}

.trustech-location-text {
    text-align: left;
    padding: 10px 30px;
}

@media screen and (max-width: 1024px) {
    .trustech-location-text {
        text-align: center;
    }

    .trustech-banner-container {
        flex-direction: column;
    }
}

</style>
