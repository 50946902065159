<template>
  <nav :class="{ navOnScroll: pageScrolled}">
    <router-link to="/" @click="closeProductsMenu" class="cilab-logo">
      <Logo main_color="cilab-blue"
        second_color="cilab-grey"
        :width="125"
        :height="55"/>
    </router-link>
    <a @click="changeProductsMenuStatus();" class="router-link-hover"
      :class="{ hightlightProductsMenu: menuIsActive}">Products</a>
    <a href="https://support.cilab.com/login_page.php" target="_blank" class="router-link-hover">Support</a>
    <router-link to="/about" @click="closeProductsMenu" class="router-link-hover">About</router-link>
    <router-link to="/career" @click="closeProductsMenu" class="router-link-hover">Career</router-link>
  </nav>
  <nav class="products-menu"
      :class="{ productsMenuActive: menuIsActive }">
      <div class="products-child-menu test-systems">
        <div>
          <h4 class="products-child-menu-heading">Test Systems</h4>

          <router-link to="/product-ci200" @click="closeProductsMenu" class="products-child-menu-item">ci200 - Multi Interface</router-link>
          <router-link to="/product-ci220" @click="closeProductsMenu" class="products-child-menu-item">ci220 - Contact</router-link>
          <router-link to="/product-ci230" @click="closeProductsMenu" class="products-child-menu-item">ci230 - NFC</router-link>          
        </div>
      </div>
      <div class="products-child-menu">
        <div>
          <h4 class="products-child-menu-heading">Accessories</h4>

          <router-link to="/product-robots" @click="closeProductsMenu" class="products-child-menu-item">Robots</router-link>
          <router-link to="/product-reference-antennas" @click="closeProductsMenu" class="products-child-menu-item">Reference Antennas</router-link>
        </div>
      </div>
  </nav>
</template>

<script>
import Logo from "@/components/AppLogo.vue"

export default {
  name: 'Navbar',
  components: {
    Logo
  },
  data() {
    return {
      menuIsActive: false,
      pageScrolled: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  emits: ['changedProductMenuStateOpen', 'changedProductMenuStateClosed'],
  methods: {
    changeProductsMenuStatus() {
      this.menuIsActive = !this.menuIsActive
      this.menuIsActive ? this.$emit('changedProductMenuStateOpen') : this.$emit('changedProductMenuStateClosed')  
    },
    closeProductsMenu() {
      this.menuIsActive = false
      this.$emit('changedProductMenuStateClosed') 
    },
    handleScroll() {
      var header = document.getElementById('header')
      var headerOffset = header.offsetTop

      window.scrollY > headerOffset ? this.pageScrolled = true : this.pageScrolled = false
    }
  }
}
</script>

<style scoped>
  .cilab-logo {
    padding: 10px 30px 10px 0; 
  }

  nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: 4px solid var(--cilab-blue);
    background: white;
    box-shadow: 0;
    transition: box-shadow .2s;
  }

  nav a {
    padding: 30px 2% 30px 2%;
    color: #2c3e50;
    text-decoration: none;
  }

  .navOnScroll { 
    transition: box-shadow .2s;
    box-shadow: 0 5px 3px 0 rgba(var(--cilab-grey-rgb), 0.5);
  }

  /* let hover effect stay active if link is active */
  nav a.router-link-exact-active {
    color: var(--cilab-blue);
    position: relative;
  }

  nav a.router-link-exact-active::before {
    position: absolute;
    content: '';
    height: 2px;
    bottom: 0px; 
    margin: 0 auto;
		left: 0;
    right: 0;
		width: 100%;
		background: var(--cilab-blue);
  }

  a.router-link-exact-active.products-child-menu-item::before {
    content: none;
  }

  nav a.router-link-exact-active.cilab-logo::before {
    content: none;
  }

  /* hover effect for main menu */
  nav a:hover {
    cursor: pointer;
    color: var(--cilab-blue)
  }

  .router-link-hover:hover {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
  }

  .router-link-hover::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    top: 77px;  
    background: var(--cilab-blue);
    height: 2px;    
    transition-property: left, right;
    transition-duration: .4s;
    transition-timing-function: ease-out;
  }

  .router-link-hover:hover::after, .router-link-hover:focus::after, .router-link-hover:active::after {
    left: 0;
    right: 0;
  }

  /* products menu */
  .products-menu {
    border-top: 0px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    height: 0px;
    overflow: hidden;
    transition: height .5s ease, opacity .4s ease;
    opacity: 0;
  }

  .productsMenuActive {
    height: 140px;
    opacity: 1;
    transition: height .5s ease, opacity .4s ease;
    border-top: 1px solid var(--cilab-grey);
  }

  .hightlightProductsMenu {
    color: var(--cilab-blue);
  }

  .products-child-menu {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 50%;
    height: 110px;
    margin: 15px 0 15px 0;
    padding: 10px;
  }

  .products-child-menu a:hover {
    color: var(--cilab-blue);
    transition: .2s;
  }

  .products-child-menu-heading {
    margin: 0;
    padding-bottom: 15px;
  }

  .products-child-menu-item {
    padding: 0;
    display: inline-block;
    width: 100%;
    padding: 3px;
    text-align: left;
  }

  .test-systems {
    border-right: 1px solid var(--cilab-grey);
  }
</style>