<template>
  <footer class="footer-distributed">
    <div class="footer-left">
      <router-link to="/">
          <Logo main_color="cilab-blue"
                second_color="cilab-grey"
                :width="150"
                :height="55" />
      </router-link>
      <p class="footer-links">
        <router-link to="/imprint" class="products-child-menu-item">Imprint | GDPR</router-link>
      </p>
      <p class="footer-company-name">cilab GmbH &copy; 2020-{{ current_year }}</p>
    </div>

    <div class="footer-center">
      <div>
          <i class="fa fa-map-marker-alt"></i>
          <p><span>Neuseiersbergerstraße 115</span> 8055 Graz, AUSTRIA</p>
      </div>

      <div>
          <i class="fa fa-phone"></i>
          <p>+43 (0)664 88248917</p>
      </div>

      <div>
          <i class="fa fa-envelope"></i>
          <p><a href="mailto:contact@cilab.com">contact[at]cilab.com</a></p>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from '@/components/AppLogo.vue'

export default {
  name: 'Footer',
  components: {
    Logo
  },
  data() {
    return {
      current_year: new Date().getFullYear()
    }
  },  
}
</script>

<style scoped>
  footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    z-index: 0;
  }

  @media screen and (max-width: 1024px) {
    footer {
      position: relative;
    }
  }

  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }

  .footer-distributed {
    background: rgba(35, 35, 35, 1);
    border-bottom: 4px solid rgba(0, 92, 169, 1);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 35px 150px;
  }

  @media screen and (max-width: 700px) {
    .footer-distributed {
      padding: 15px 10px;
    } 
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
  }

  /* Footer left */
  .footer-distributed .footer-left {
    width: 40%;
  }

  /* Footer links */
  .footer-distributed .footer-links {
    color: #ffffff;
    margin: 20px 0 12px;
    padding: 0;
  }

  .footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    font-weight: 400;
    text-decoration: none;
    color: inherit;
  }

  .footer-distributed .footer-company-name {
    color: white;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
  }

  /* Footer Center */
  .footer-distributed .footer-center {
    width: 35%;
  }

  .footer-distributed .footer-center i {
    background-color: #33383b;
    color: #ffffff;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
    border-right: 2px solid rgba(0, 92, 169, 1);
  }

  .footer-distributed .footer-center i.fa-envelope {
    font-size: 17px;
    line-height: 38px;
  }

  .footer-distributed .footer-center p {
    display: inline-block;
    color: #ffffff;
    font-weight: 400;
    vertical-align: middle;
    margin: 0;
  }

  .footer-distributed .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
  }

  .footer-distributed .footer-center p a {
    color: rgba(0, 92, 169, 1);
    text-decoration: none;
  }

  /* Footer Right */
  .footer-distributed .footer-right {
    width: 20%;
  }

  .footer-distributed .footer-company-about {
    line-height: 20px;
    color: #92999f;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
  }

  .footer-distributed .footer-company-about span {
    display: block;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .footer-distributed .footer-icons {
    margin-top: 25px;
  }

  .footer-distributed .footer-icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: #33383b;
    border-radius: 2px;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    margin-right: 3px;
    margin-bottom: 5px;
  }

@media (max-width: 880px) {

  .footer-distributed {
    font: bold 14px sans-serif;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
  }
}

</style>